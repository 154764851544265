import * as React from "react";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import CallToAction from "../components/Section/CallToAction";
import Impacts, { Impact } from "../components/Section/Impacts";
import Contact from "../components/Section/Contact";

interface IProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    impact: {
      data: {
        title: {
          text: string;
        };
        cover: any;
        gallery: Array<{
          gallery_images: any;
        }>;
        sidebar_body: {
          html: string;
        };
        body: {
          text: string;
          html: string;
        };
        website: {
          url: string;
        };
      };
    };
    impacts: {
      nodes: Impact[];
    };
    home: {
      nodes: Array<any>;
    };
    contact: {
      nodes: Array<any>;
    };
  };
  pageContext: any;
  location: Location;
}
const ImpactTemplate = ({ data, pageContext, location }: IProps) => {
  const impact = data.impact;
  const siteTitle = data.site.siteMetadata.title;
  const homeData = data.home.nodes[0].data;
  const images = impact.data.gallery.map(image => image.gallery_images);

  const options = {
    enablePanzoom: false,
  };

  return (
    <SimpleReactLightbox>
      <Layout location={location} title={siteTitle} pageContext={pageContext}>
        <SEO
          title={impact.data.title.text}
          description={impact.data.body.text.substr(0, 200)}
        />
        <BackgroundImage
          className="breadcrumb-area pt-230 pb-235"
          fluid={impact.data.cover.localFile.childImageSharp.fluid}
        />
        <div className="blog-area pt-120 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <article className="postbox post format-image mb-40">
                  <div className="postbox__text bg-none">
                    <h3 className="blog-title">{impact.data.title.text}</h3>
                    <div
                      className="post-text mb-20"
                      dangerouslySetInnerHTML={{
                        __html: impact.data.body.html,
                      }}
                    ></div>
                  </div>
                </article>
              </div>
              <div className="col-lg-4">
                <div className="widget post-text mb-40">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: impact.data.sidebar_body.html,
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <SRLWrapper options={options}>
              <div className="row">
                {images.map((image, index) => (
                  <div
                    key={`gallery-image-${index}`}
                    className="col-md-4 col-xs-6"
                  >
                    <img
                      src={image.url}
                      alt={
                        image.alt || `${impact.data.title.text} ${index + 1}`
                      }
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                ))}
              </div>
            </SRLWrapper>
          </div>
        </div>
        <CallToAction data={homeData} />
        <Impacts data={homeData} cases={data.impacts.nodes} />
        <Contact data={data.contact} />
      </Layout>
    </SimpleReactLightbox>
  );
};

export default ImpactTemplate;

export const pageQuery = graphql`
  query ImpactBySlug($uid: String!, $locale: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    impact: prismicCases(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        body: full_body {
          html
          text
        }
        cover {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        gallery {
          gallery_images {
            alt
            url
          }
        }
        sidebar_body {
          html
          text
        }
        title {
          text
        }
        website {
          url
        }
      }
    }
    home: allPrismicHomepage(filter: { lang: { eq: $locale } }) {
      nodes {
        data {
          call_to_action {
            title {
              text
            }
            description {
              text
            }
            link {
              url
            }
            button_title {
              text
            }
            phone {
              text
            }
          }
          cases {
            title {
              text
            }
            description {
              text
            }
          }
        }
      }
    }
    impacts: allPrismicCases(filter: { lang: { eq: $locale } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          cover {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    contact: allPrismicForms(filter: { lang: { eq: $locale } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          description {
            text
            html
          }
          backgroundimage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          buttontitle {
            text
          }
          successmessage {
            text
          }
          errormessage {
            text
          }
          body {
            slice_type
            items {
              label {
                text
              }
              field_name {
                text
              }
              type
              placeholder {
                text
              }
            }
          }
        }
      }
    }
  }
`;
